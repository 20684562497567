import {FC} from 'react'

type Props = {
  to_number?: string
}

const UserLastLoginCell: FC<Props> = ({to_number}) => (
  <div className='text-gray-600  mb-1'>{to_number ? to_number : '-'}</div>
)

export {UserLastLoginCell}
