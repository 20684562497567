/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import noUiSlider from 'nouislider'
import {useLayout} from '../../core'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {DefaultTitle} from './page-title/DefaultTitle'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useLocation, useNavigate} from 'react-router-dom'

const HeaderToolbar = () => {
  const {classes} = useLayout()
  const [status, setStatus] = useState<string>('1')
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const rangeSlider = document.querySelector('#kt_toolbar_slider')
    const rangeSliderValueElement = document.querySelector('#kt_toolbar_slider_value')

    if (!rangeSlider || !rangeSliderValueElement) {
      return
    }

    // @ts-ignore
    noUiSlider.create(rangeSlider, {
      start: [5],
      connect: [true, false],
      step: 1,
      format: {
        to: function (value) {
          const val = +value
          return Math.round(val).toString()
        },
        from: function (value) {
          return value
        },
      },
      range: {
        min: [1],
        max: [10],
      },
    })

    // @ts-ignore
    rangeSlider.noUiSlider.on('update', function (values, handle) {
      rangeSliderValueElement.innerHTML = values[handle]
    })

    const handle = rangeSlider.querySelector('.noUi-handle')
    if (handle) {
      handle.setAttribute('tabindex', '0')
    }

    // @ts-ignore
    handle.addEventListener('click', function () {
      // @ts-ignore
      this.focus()
    })

    // @ts-ignore
    handle.addEventListener('keydown', function (event) {
      // @ts-ignore
      const value = Number(rangeSlider.noUiSlider.get())
      // @ts-ignore
      switch (event.which) {
        case 37:
          // @ts-ignore
          rangeSlider.noUiSlider.set(value - 1)
          break
        case 39:
          // @ts-ignore
          rangeSlider.noUiSlider.set(value + 1)
          break
      }
    })
    return () => {
      // @ts-ignore
      rangeSlider.noUiSlider.destroy()
    }
  }, [])

  const handleMetaDataClick = () => {
    if (location.pathname === '/hospitality-agent') {
      navigate('/hospitality-agent/metadata')
    } else if (location.pathname === '/hospitality-arabic-agent') {
      navigate('/hospitality-arabic-agent/metadata')
    } else if (location.pathname === '/doctor-appointment-agent') {
      navigate('/doctor-appointment-agent/metadata')
    } else if (location.pathname === '/logistics-agent') {
      navigate('/logistics-agent/metadata')
    } else if (location.pathname === '/banking-agent') {
      navigate('/banking-agent/metadata')
    }
  }

  return (
    <div className='toolbar d-flex align-items-stretch'>
      {/* begin::Toolbar container */}
      <div
        className={`${classes.headerContainer.join(
          ' '
        )} py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between`}
      >
        <DefaultTitle />
        <div className='d-flex align-items-stretch pt-3 pt-lg-0'>
          {/* begin::Action wrapper */}
          <div className='d-flex align-items-center gap-4'>
            {/* begin::Meta Data Action  */}
            {location.pathname === '/dashboard' ||
            location.pathname === '/call-logs' ||
            location.pathname === '/session-details' ||
            location.pathname === '/hospitality-agent/metadata' ? (
              ''
            ) : (
              <button className='btn btn-primary px-4 py-3' onClick={handleMetaDataClick}>
                <span className='fw-bold'>Meta Data</span>
              </button>
            )}

            {/* end::Meta Data Action  */}

            {/* begin::Actions */}
            <div className='d-flex'>
              {/* begin::Theme mode */}
              <div className='d-flex align-items-center'>
                <ThemeModeSwitcher toggleBtnClass='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary' />
              </div>
              {/* end::Theme mode */}
            </div>
            {/* end::Actions */}
            {/*begin::User menu*/}
            <div>
              {/*begin::Action*/}
              <a
                href='#'
                className='btn btn-icon btn-sm btn-active-color-primary mt-n2'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-overflow='false'
              >
                <img
                  src={toAbsoluteUrl('/media/avatars/300-1.jpg')}
                  alt=''
                  height={40}
                  width={40}
                  style={{borderRadius: '50%'}}
                />
              </a>

              <HeaderUserMenu />
              {/*end::Action*/}
            </div>
            {/*end::User menu*/}
          </div>
          {/* end::Action wrapper */}
        </div>
        {/* end::Toolbar container */}
      </div>
    </div>
  )
}

export {HeaderToolbar}
