import {useAxiosInstance} from '../../../utils/interceptors'
import {AuthModel, ResetPasswordModel} from './_models'

const LOGIN_URL = '/login/'
const REQUEST_PASSWORD_URL = '/forgot-password/'
const RESET_PASSWORD_URL = '/reset-password/'

export const useAuthService = () => {
  const axiosInstance = useAxiosInstance()
  // Server should return AuthModel
  const login = (email: string, password: string) => {
    return axiosInstance.post<AuthModel>(LOGIN_URL, {
      email,
      password,
    })
  }

  // Server should return object => { result: boolean } (Is Email in DB)
  const requestPassword = (email: string) => {
    return axiosInstance.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
      email,
    })
  }

  const resetPassword = (new_password: string, token: string, uidb64: string) => {
    return axiosInstance.post<ResetPasswordModel>(RESET_PASSWORD_URL, {
      new_password,
      token,
      uidb64,
    })
  }
  return {login, requestPassword, resetPassword}
}

export default useAuthService
