import React from 'react'
import '../style/SessionDetails.css'

type SessionDetailsProps = {
  sessionDetails: any
}

const SessionChat: React.FC<SessionDetailsProps> = ({sessionDetails}) => {
  return (
    <div className='session-details card p-5'>
      <h2>Session Details</h2>
      <div className='chat-container mt-5 mb-5'>
        {sessionDetails?.messages.map((message: any) => (
          <div
            key={message.id}
            className={`message ${message.messenger === 'Agent' ? 'bot-message' : 'user-message'}`}
          >
            <strong>{message.messenger}:</strong> {message.message_text}
          </div>
        ))}
      </div>
    </div>
  )
}

export default SessionChat
