import {FC} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import ArabicMetaDataList from './components/ArabicMetaDataList'

const HospitalityArabicAgentMetaData: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ARABIC.META.DATA.TITLE'})}</PageTitle>
      <ArabicMetaDataList />
    </>
  )
}

export default HospitalityArabicAgentMetaData
