import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import HospitalityAgentPage from '../pages/active-sessions/ActiveSessionsPage'
import HospitalAgentList from '../pages/Hospitality-Agent/HospitalAgentList'
import SessionDetailsPage from '../pages/SessionsDetails/SessionDetailsPage'
import HospitalityAgentMetaData from '../pages/Hospitality-Agent/HospitalityAgentMetaData/HospitalityAgentMetaData'
import DoctorAppointmentAgentPage from '../pages/Doctor-Appointment-Details/DoctorAppointmentAgent/DoctorAppointmentAgentPage'
import CallLogsPage from '../pages/CallLogs/CallLogsPage'
import DoctorAppAgentMetaData from '../pages/Doctor-Appointment-Details/DoctorAppAgentMetaData/DoctorAppAgentMetaData'
import LogisticsAgentPage from '../pages/Logistics-Agent/LogisticsAgent/LogisticsAgentPage'
import LogisticsAgentMetaData from '../pages/Logistics-Agent/LogisticsAgentMetaData/LogisticsAgentMetaData'
import BankingAgentPage from '../pages/Banking-Agent/BankingAgent/BankingAgentPage'
import BankingAgentMetaData from '../pages/Banking-Agent/BankingAgentMetaData/BankingAgentMetaData'
import ArabicHospitalAgentList from '../pages/Hospitality-Arabic-Agent/HospitalArabicAgentList'
import HospitalityArabicAgentMetaData from '../pages/Hospitality-Arabic-Agent/HospitalityArabicAgentMetaData/HospitalityArabicAgentMetaData'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />

        <Route
          path='/hospitality-agent'
          element={
            <SuspensedView>
              <HospitalAgentList />
            </SuspensedView>
          }
        />

        <Route
          path='/hospitality-arabic-agent'
          element={
            <SuspensedView>
              <ArabicHospitalAgentList />
            </SuspensedView>
          }
        />

        <Route
          path='/doctor-appointment-agent'
          element={
            <SuspensedView>
              <DoctorAppointmentAgentPage />
            </SuspensedView>
          }
        />

        <Route
          path='/logistics-agent'
          element={
            <SuspensedView>
              <LogisticsAgentPage />
            </SuspensedView>
          }
        />

        <Route
          path='/banking-agent'
          element={
            <SuspensedView>
              <BankingAgentPage />
            </SuspensedView>
          }
        />

        <Route
          path='/call-logs'
          element={
            <SuspensedView>
              <CallLogsPage />
            </SuspensedView>
          }
        />

        <Route
          path='/hospitality-agent/metadata'
          element={
            <SuspensedView>
              <HospitalityAgentMetaData />
            </SuspensedView>
          }
        />

        <Route
          path='/hospitality-arabic-agent/metadata'
          element={
            <SuspensedView>
              <HospitalityArabicAgentMetaData />
            </SuspensedView>
          }
        />

        <Route
          path='/doctor-appointment-agent/metadata'
          element={
            <SuspensedView>
              <DoctorAppAgentMetaData />
            </SuspensedView>
          }
        />

        <Route
          path='/logistics-agent/metadata'
          element={
            <SuspensedView>
              <LogisticsAgentMetaData />
            </SuspensedView>
          }
        />

        <Route
          path='/banking-agent/metadata'
          element={
            <SuspensedView>
              <BankingAgentMetaData />
            </SuspensedView>
          }
        />

        <Route
          path='/session-details'
          element={
            <SuspensedView>
              <SessionDetailsPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
