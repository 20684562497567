/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React from 'react'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'

const UsersListPagination: React.FC = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()

  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({page, page_size: pagination.page_size || 10})
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            {/* Previous Button */}
            <li
              className={clsx('page-item', {
                disabled: pagination.page === 1 || isLoading,
              })}
            >
              <a
                className='page-link'
                onClick={() => updatePage(pagination.page - 1)}
                style={{cursor: 'pointer'}}
              >
                Previous
              </a>
            </li>

            {/* Page Numbers */}
            {pagination.links.map((link: any) => (
              <li
                key={link.page}
                className={clsx('page-item', {
                  active: pagination.page === link.page,
                  disabled: isLoading,
                })}
              >
                <a
                  className='page-link'
                  onClick={() => updatePage(link.page)}
                  style={{cursor: 'pointer'}}
                >
                  {link.label}
                </a>
              </li>
            ))}

            {/* Next Button */}
            <li
              className={clsx('page-item', {
                disabled: pagination.page === pagination.page_count || isLoading,
              })}
            >
              <a
                className='page-link'
                onClick={() => updatePage(pagination.page + 1)}
                style={{cursor: 'pointer'}}
              >
                Next
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {UsersListPagination}
