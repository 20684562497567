import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {ActiveSessionsListWrapper} from './active-sessions-list/ActiveSessionsList'
type UsersTableProps = {
  data: any[] // Use appropriate type instead of `any` if you have a model/interface for the data
}

const ActiveSessionsPage: React.FC<UsersTableProps> = ({data}) => {
  const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'HOSPITALITY.AGENT.TITLE'})}</PageTitle> */}
      <ActiveSessionsListWrapper data={data} />
    </>
  )
}

export default ActiveSessionsPage
