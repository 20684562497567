import React from 'react'
import neutral_img from '../../../../_metronic/assets/images/sad.png'
import negative_img from '../../../../_metronic/assets/images/sad1.png'
import positive_img from '../../../../_metronic/assets/images/best_smile.png'

type SessionDetailsProps = {
  sessionDetails: any
}

const CallSummary: React.FC<SessionDetailsProps> = ({sessionDetails}) => {
  // Map the threshold to corresponding images
  const getImageForThreshold = (threshold: string): string | undefined => {
    switch (threshold) {
      case 'positive':
        return positive_img // Happy face for positive
      case 'negative':
        return negative_img // Sad face for negative
      case 'neutral':
        return neutral_img // Neutral face
      default:
        return undefined // No image for unknown threshold
    }
  }

  // Utility function to format any key dynamically
  const formatKey = (key: string) => {
    // Convert snake_case to Title Case
    return key
      .split('_') // Split by underscore
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
      .join(' ') // Join the words with a space
  }

  // Function to render key-value pairs, handling nested objects
  const renderSummary = (summary: any) => {
    return Object.entries(summary)
      .map(([key, value]) => {
        if (value === '' || value === null) {
          return null
        }

        if (Array.isArray(value)) {
          return (
            <li key={key} className='mb-2'>
              <span className='fw-bold  fs-5'>{formatKey(key)}:</span>
              <ul className='list-none ms-4'>
                {value.map((item, index) => (
                  <li key={index} className='mb-1'>
                    {typeof item === 'object' && item !== null ? (
                      <ul className='list-none ms-4'>{renderSummary(item)}</ul>
                    ) : (
                      String(item)
                    )}
                  </li>
                ))}
              </ul>
            </li>
          )
        } else if (typeof value === 'object' && value !== null) {
          return (
            <li key={key} className='mb-2'>
              <span className='fw-bold  fs-5'>{formatKey(key)}:</span>
              <ul className='ms-4' style={{listStyle: 'disc'}}>
                {renderSummary(value)}
              </ul>
            </li>
          )
        } else {
          return (
            <li key={key} className='mb-2'>
              <span className='fw-bold fs-5'>{formatKey(key)}:</span> {String(value)}
            </li>
          )
        }
      })
      .filter(Boolean)
  }

  return (
    <div className='mt-4 mb-4 p-4 border rounded shadow bg-light'>
      <h2 className='mb-3 d-flex align-items-center gap-2'>
        Call Summary{' '}
        {sessionDetails?.threshold && (
          <img
            src={getImageForThreshold(sessionDetails.threshold) || undefined}
            alt={`${sessionDetails.threshold} face`}
            style={{width: '30px', height: '30px'}} // Adjust image size here
          />
        )}
      </h2>
      <div>
        {/* Dynamically render the summary details */}
        {sessionDetails?.summary && (
          <ul className='list-none'>{renderSummary(sessionDetails.summary)}</ul>
        )}
      </div>
    </div>
  )
}

export default CallSummary
