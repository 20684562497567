import React from 'react'

const TopIntentsList = () => {
  return (
    <>
      <div className='border mb-5 p-3 rounded-3 card' style={{height: '200px'}}>
        <span className='fs-5 fw-bold'>Top Intents</span>{' '}
        <div
          className='text-muted align-items-center d-flex justify-content-center'
          style={{height: '150px'}}
        >
          Not enough data to display the result
        </div>
      </div>
    </>
  )
}

export default TopIntentsList
