import {useAxiosInstance} from '../../../../utils/interceptors'
import {CallQueryResponse} from './_models'
import {AxiosResponse} from 'axios'

const GET_COMPLETED_SESSIONS_URL = `completed-sessions`
const GET_CONVERSATION_LOG_URL = `/conversation-log`
const GET_SESSIONS_DETAILS_URL = `/conversation-log/call_sessions/detail`

export const useUserService = () => {
  const axiosInstance = useAxiosInstance()

  const getSessionsHistory = (agetId: any, query: string): Promise<any> => {
    return axiosInstance
      .get(`${GET_CONVERSATION_LOG_URL}/${agetId}/${GET_COMPLETED_SESSIONS_URL}?${query}`)
      .then((d: AxiosResponse<any>) => d.data)
  }

  const getSessionDetailsById = (sessionId: any): Promise<any> => {
    return axiosInstance
      .get(`${GET_SESSIONS_DETAILS_URL}/${sessionId}`)
      .then((d: AxiosResponse<any>) => d.data)
  }

  return {
    getSessionsHistory,
    getSessionDetailsById,
  }
}

export default useUserService
