/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../../../_metronic/helpers'
import useBankinAgentService from './_requests'
import {User} from './_models'
import {useQueryRequest} from './QueryRequestProvider'

const QueryResponseContext = createResponseContext<User>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const {getBankAccountDetails} = useBankinAgentService()

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.USERS_LIST}-${query}`,
    () => {
      return getBankAccountDetails(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()

  if (!response) {
    return []
  }

  return response?.results || []
}

const useQueryResponsePagination = (): any => {
  const defaultPaginationState: any = {
    page: 1,
    page_size: 10,
    page_count: 1,
    count: 0,
    links: [],
  }

  const {response} = useQueryResponse()

  if (!response || !response.results || !response.count) {
    return defaultPaginationState
  }

  const {page_number, page_size, count}: any = response

  // Calculate the total number of pages
  const page_count = Math.ceil(count / page_size)

  // Generate pagination links dynamically based on the page count
  const links = Array.from({length: page_count}, (_, i) => ({
    label: `${i + 1}`,
    page: i + 1,
  }))

  return {
    page: page_number,
    page_size,
    page_count,
    count,
    links,
  }
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
