import React, {useEffect, useState} from 'react'
import SessionChat from './SessionChat'
import CallSummaryChart from './CallSummaryChart'
import CallSummary from './CallSummary'
import {useLocation} from 'react-router-dom'
import useUserService from '../../completed-sessions/completed-sessions-list/core/_requests'

interface LocationState {
  sessionId?: string
}

const SessionDetails: React.FC = () => {
  const location = useLocation() as {state: LocationState}
  const sessionId = location.state?.sessionId || null
  const {getSessionDetailsById} = useUserService()
  const [sessionDetails, setSessionDetails] = useState<any>(null)

  const fetchCompletedSessionDetails = async () => {
    try {
      const response = await getSessionDetailsById(sessionId)
      setSessionDetails(response?.data)
    } catch (error) {
      console.error('Error fetching completed session details:', error)
    } finally {
    }
  }

  useEffect(() => {
    fetchCompletedSessionDetails()

    const intervalId = setInterval(() => {
      if (sessionDetails?.has_call_ended === false) {
        fetchCompletedSessionDetails()
      } else {
        clearInterval(intervalId)
      }
    }, 60000)

    return () => clearInterval(intervalId)
  }, [sessionId, sessionDetails?.has_call_ended])

  return (
    <>
      <div className='session-details-section'>
        {/* start call summary details  */}
        {sessionDetails?.has_call_ended && (
          <div className='call-summary'>
            <CallSummary sessionDetails={sessionDetails} />
          </div>
        )}

        {/* end call summary details  */}

        {/* start session details in chat form  */}
        <div className='chat-session'>
          <SessionChat sessionDetails={sessionDetails} />
        </div>
        {/* end session details in chat form  */}

        {/* start call summary chart  */}
        <div className='call-summary-chart'>
          <CallSummaryChart sessionDetails={sessionDetails} />
        </div>
        {/* end call summary chart  */}
      </div>
    </>
  )
}

export default SessionDetails
