import {useAxiosInstance} from '../../../../utils/interceptors'
import {ID} from '../../../../../_metronic/helpers'
import {AxiosResponse} from 'axios'

const GET_CONVERSATION_LOG_URL = `/conversation-log`
const GET_ACTIVE_SESSIONS_URL = `active-sessions`

export const useHospitalityAgentService = () => {
  const axiosInstance = useAxiosInstance()

  const getActiveSessions = (agetId: any, page: string, page_size: string): Promise<any> => {
    return axiosInstance
      .get(
        `${GET_CONVERSATION_LOG_URL}/${agetId}/${GET_ACTIVE_SESSIONS_URL}?page=${page}&page_size=${page_size}`
      )
      .then((d: AxiosResponse<any>) => d.data)
  }

  return {
    getActiveSessions,
  }
}

export default useHospitalityAgentService
