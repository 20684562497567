import React from 'react'
import {Line} from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Tick,
} from 'chart.js'

type SessionDetailsProps = {
  sessionDetails: any
}

// Register necessary components for Chart.js
ChartJS.register(LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend)

const CallSummaryChart: React.FC<SessionDetailsProps> = ({sessionDetails}) => {
  // Extract sentiment scores from sessionDetails.messages
  const sentimentScores =
    sessionDetails?.messages.map((message: any) => message.sentiment_score) || []

  // Data for the chart
  const data = {
    labels: sessionDetails?.messages.map((message: any, index: number) => `Message ${index + 1}`), // Use message index as label
    datasets: [
      {
        label: 'Sentiment Score',
        data: sentimentScores,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
        tension: 0.1,
      },
    ],
  }

  // Options for the chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            const score = tooltipItem.raw as number
            if (score > 0) return 'Positive'
            if (score === 0) return 'Neutral'
            return 'Negative'
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Conversation',
        },
        ticks: {
          callback: function (value: string | number, index: number, values: Tick[]) {
            const totalMessages = values.length
            if (index === 0) return 'Start'
            if (index === Math.floor(totalMessages / 2)) return 'Middle'
            if (index === totalMessages - 1) return 'End'
            return ''
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Sentiment Score',
        },
        ticks: {
          callback: (tickValue: string | number) => {
            if (typeof tickValue === 'number') {
              if (tickValue === 1) return 'Positive'
              if (tickValue === 0) return 'Neutral'
              if (tickValue === -1) return 'Negative'
            }
            return ''
          },
        },
        min: -1,
        max: 1,
        stepSize: 1,
      },
    },
  }

  return (
    <div className='card p-5' style={{marginTop: '30px'}}>
      <h2>Sentiment Score Chart</h2>
      <Line data={data} options={options} />
    </div>
  )
}

export default CallSummaryChart
