import {FC} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {PackagesListWrapper} from './package-list/PackagesList'

const PackagePage: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'PACKAGES_DETAILS_TITLE'})}</PageTitle>
      <PackagesListWrapper />
    </>
  )
}

export default PackagePage
