import {FC} from 'react'

type Props = {
  payment_done_time?: string
}

const UserTwoStepsCell: FC<Props> = ({payment_done_time}) => {
  const formattedDate = payment_done_time ? new Date(payment_done_time).toLocaleDateString() : ''

  return <div className='text-gray-800 text-hover-primary mb-1'>{formattedDate}</div>
}

export {UserTwoStepsCell}
