import React, {FC} from 'react'

type Props = {
  from_number?: string
}

const UserFromCell: FC<Props> = ({from_number}) => (
  <div className='text-gray-600 mb-1'>{from_number}</div>
)

export {UserFromCell}
