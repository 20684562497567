import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/CompletedQueryResponseProvider'
import {usersColumns} from './columns/_columns'
import {Call} from '../core/_models'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {UsersListPagination} from '../components/pagination/UsersListPagination'
import {KTCardBody} from '../../../../../_metronic/helpers'

type UsersTableProps = {
  data: Call[]
  currentPagination: any // Use appropriate type instead of `any` if you have a model/interface for the data
  setCurrentPagination: any
}

const CompletedSessionsTable: React.FC<UsersTableProps> = ({
  data,
  currentPagination,
  setCurrentPagination,
}) => {
  const isLoading = useQueryResponseLoading()
  const columns = useMemo(() => usersColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable<Call>({
    columns,
    data,
  })

  return (
    <KTCardBody className='card mt-5 mb-5'>
      <div className='table-responsive mb-5'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Call>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Call>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <UsersListPagination
        currentPagination={currentPagination}
        setCurrentPagination={setCurrentPagination}
      />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  )
}

export {CompletedSessionsTable}
