import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {KTCard} from '../../../../_metronic/helpers'
import {ActiveSessionsTable} from './table/ActiveSessionsTable'

type UsersTableProps = {
  data: any[]
}

const ActiveSessionsList: React.FC<UsersTableProps> = ({data}) => {
  return (
    <>
      <KTCard>
        {/* <UsersListHeader /> */}
        <ActiveSessionsTable data={data} />
      </KTCard>
      {/* {itemIdForUpdate !== undefined && <UserEditModal />} */}
    </>
  )
}

const ActiveSessionsListWrapper: React.FC<UsersTableProps> = ({data}) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ActiveSessionsList data={data} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ActiveSessionsListWrapper}
