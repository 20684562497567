// @ts-nocheck
import {Column} from 'react-table'
import {UserCustomHeader} from './UserCustomHeader'
import {User} from '../../core/_models'
import {AppointmentDateFormat} from './AppointmentDateFormat'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Appointment Id' className='min-w-125px' />
    ),
    id: 'id',
    accessor: (row) => row?.id,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Doctor Name' className='min-w-125px' />
    ),
    id: 'name',
    accessor: (row) => row.doctor?.name,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Patient Name' className='min-w-125px' />
    ),
    id: 'patient_name',
    accessor: (row) => row.patient?.name,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Department Name' className='min-w-125px' />
    ),
    id: 'department_name',
    accessor: (row) => row.doctor?.department_name,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Appointment Date' className='min-w-125px' />
    ),
    id: 'appointment_date',
    Cell: ({...props}) => <AppointmentDateFormat user={props.data[props.row.index]} />,
  },
]

export {usersColumns}
