import {FC} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import BankingAgent from '../BankingAgent'

const BankingAgentPage: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'BANKING.AGENT.TITLE'})}</PageTitle>
      <BankingAgent />
    </>
  )
}

export default BankingAgentPage
