import React, {useState, useRef, useContext} from 'react'
import {MdFilterList} from 'react-icons/md'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import 'bootstrap-daterangepicker/daterangepicker.css'
import {loginContext} from '../../../../app/context/context'

const FilterOption = () => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [selectedOption, setSelectedOption] = useState('')
  const {setAgentType, setFilterDashboardData} = useContext(loginContext)
  const [startDate, setStartDate] = useState<string | undefined>()
  const [endDate, setEndDate] = useState<string | undefined>()
  const [dateRange, setDateRange] = useState<string>('') // To manage the displayed date range

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedAgent = event.target.value
    setSelectedOption(selectedAgent)
    setAgentType(selectedAgent)
    filterData({agentType: selectedAgent})

    // Close the dropdown when a selection is made
    if (dropdownRef.current) {
      const dropdownMenu = dropdownRef.current
      dropdownMenu.classList.remove('show')
    }
  }

  const handleDateApply = (event: any, picker: any) => {
    const start = picker.startDate.format('YYYY-MM-DD')
    const end = picker.endDate.format('YYYY-MM-DD')
    setStartDate(start)
    setEndDate(end)
    setDateRange(
      `${picker.startDate.format('MMM D, YYYY')} - ${picker.endDate.format('MMM D, YYYY')}`
    ) // Update the input field with the selected range
    filterData({startDate: start, endDate: end})
  }

  const filterData = (updatedFilter?: {
    startDate?: string
    endDate?: string
    agentType?: string
  }) => {
    // Combine the current state values with the new filter updates
    const filter = {
      start_date: updatedFilter?.startDate || startDate,
      end_date: updatedFilter?.endDate || endDate,
      agent_id: updatedFilter?.agentType || selectedOption,
    }

    setFilterDashboardData({
      filter,
    })
  }

  const handleReset = () => {
    setStartDate(undefined)
    setEndDate(undefined)
    setSelectedOption('')
    setAgentType('')
    setDateRange('') // Reset the date range input

    // Reset filter data
    setFilterDashboardData({
      filter: {
        start_date: undefined,
        end_date: undefined,
        agent_id: '',
      },
    })
  }

  return (
    <div className='border mb-5 pt-3 px-3 pb-5 rounded-3 card'>
      <div className='d-flex flex-column flex-lg-row flex-md-row flex-sm-row gap-2'>
        <div className='col-xl-3'>
          <div className='d-flex gap-2 flex-column'>
            <span className='fs-5 fw-bold'>Time span</span>
            <DateRangePicker
              initialSettings={{
                autoUpdateInput: false, // Do not update the input automatically
                locale: {
                  format: 'MMM D, YYYY',
                },
              }}
              onApply={handleDateApply}
            >
              <input
                type='text'
                className='form-control'
                placeholder='Select time span'
                value={dateRange} // Display the selected date range
                readOnly // Prevent manual input
              />
            </DateRangePicker>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='d-flex' data-kt-user-table-filter='form'>
            {/* Input group */}
            <div className='d-flex flex-column'>
              <label className='form-label fs-6 fw-bold'>Agent Type:</label>
              <select
                className='form-select form-select-solid fw-bolder'
                value={selectedOption}
                onChange={handleSelectChange} // Capture agent type selection
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
              >
                <option value=''>Select agent</option>
                <option value='hospitality_agent'>Hospitality Agent</option>
                <option value='doctor_appointment_agent'>Doctor Appointment Agent</option>
                <option value='logistics_agent'>Logistics Agent</option>
                <option value='banking_agent'>Banking Agent</option>
              </select>
            </div>
          </div>
        </div>
        <div className='col-xl-1'>
          <div className='d-flex flex-column'>
            <label className='form-label fs-6 fw-bold p-3'></label>
            <button className='btn btn-secondary ' onClick={handleReset}>
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FilterOption
