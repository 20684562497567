// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserCustomHeader} from './UserCustomHeader'
import {User} from '../../core/_models'
import {PackageDeliveryDate} from '../columns/PackageDeliveryDate'
import {WarehouseArrivalDate} from '../columns/WarehouseArrivalDate'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Tracking Number' className='min-w-125px' />
    ),
    id: 'tracking_number',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Customer Name' className='min-w-125px' />
    ),
    id: 'name',
    accessor: (row) => row.customer?.name,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Warehouse Arrival' className='min-w-150px' />
    ),
    id: 'scheduled_warehouse_arrival',
    Cell: ({...props}) => <WarehouseArrivalDate user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Delivery Date' className='min-w-125px' />
    ),
    id: 'scheduled_delivery_date',
    Cell: ({...props}) => <PackageDeliveryDate user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Customer Name' className='min-w-125px' />
    ),
    id: 'warehouse_location',
    accessor: (row) => row.warehouse_location,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    accessor: (row) => row?.status,
  },
]

export {usersColumns}
