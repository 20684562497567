import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'

const CallLogsPage = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'CALL.LOGS.TITLE'})}</PageTitle>
      <div className='call-logs'>This is Call Logs Module</div>
    </div>
  )
}

export default CallLogsPage
