// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserCustomHeader} from './UserCustomHeader'
import {ActiveCall} from '../../core/_models'
import {UserLogsCell} from './UserLogsCell'
import {UserFromCell} from './UserFromCell'

const usersColumns: ReadonlyArray<Column<ActiveCall>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='ID' className='min-w-125px' />,
    id: 'id',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='From' className='min-w-125px' />,
    id: 'from',
    Cell: ({...props}) => <UserFromCell from={props.data[props.row.index].from_number} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='To' className='min-w-125px' />,
    id: 'to',
    Cell: ({...props}) => <UserLastLoginCell to={props.data[props.row.index].to_number} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Durations' className='min-w-125px' />
    ),
    id: 'durations',
    Cell: ({...props}) => (
      <UserTwoStepsCell start_datetime={props.data[props.row.index].start_datetime} />
    ),
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Logs' className='min-w-125px' />,
    id: 'logs',
    Cell: ({...props}) => <UserLogsCell logs={props.data[props.row.index]} />,
  },
]

export {usersColumns}
