import {FC} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {AppointmentsListWrapper} from './appointment-list/AppointmentsList'

const AppointmentPage: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'APPOINTMENT_DETAILS_TITLE'})}
      </PageTitle>
      <AppointmentsListWrapper />
    </>
  )
}

export default AppointmentPage
