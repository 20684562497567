import {useAxiosInstance} from '../../../../../utils/interceptors'
import {UsersQueryResponse} from './_models'
import {AxiosResponse} from 'axios'

const GET_ACCOUNT_DETAILS_URL = '/banking/bank-accounts/'

export const useBankinAgentService = () => {
  const axiosInstance = useAxiosInstance()

  const getBankAccountDetails = (query: string): Promise<UsersQueryResponse> => {
    return axiosInstance
      .get(`${GET_ACCOUNT_DETAILS_URL}?${query}`)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }
  return {getBankAccountDetails}
}

export default useBankinAgentService
