import {useAxiosInstance} from '../../../../../utils/interceptors'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {BookingQueryResponse, User, UsersQueryResponse} from './_models'
import {AxiosResponse} from 'axios'

const USER_URL = `/user`
const GET_USERS_URL = `/users/query`
const GET_ORDERS_URL = `/bookings/`

export const useUserService = () => {
  const axiosInstance = useAxiosInstance()

  const getUsers = (query: string): Promise<UsersQueryResponse> => {
    return axiosInstance
      .get(`${GET_USERS_URL}?${query}`)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  const getUserById = (id: ID): Promise<User | undefined> => {
    return axiosInstance
      .get(`${USER_URL}/${id}`)
      .then((response: AxiosResponse<Response<User>>) => response.data)
      .then((response: Response<User>) => response.data)
  }

  const createUser = (user: User): Promise<User | undefined> => {
    return axiosInstance
      .put(USER_URL, user)
      .then((response: AxiosResponse<Response<User>>) => response.data)
      .then((response: Response<User>) => response.data)
  }

  const updateUser = (user: User): Promise<User | undefined> => {
    return axiosInstance
      .post(`${USER_URL}/${user.id}`, user)
      .then((response: AxiosResponse<Response<User>>) => response.data)
      .then((response: Response<User>) => response.data)
  }

  const deleteUser = (userId: ID): Promise<void> => {
    return axiosInstance.delete(`${USER_URL}/${userId}`).then(() => {})
  }

  const getOrdersDetails = (query: string): Promise<BookingQueryResponse> => {
    return axiosInstance
      .get(`${GET_ORDERS_URL}?${query}`)
      .then((d: AxiosResponse<BookingQueryResponse>) => d.data)
  }

  return {
    getUsers,
    deleteUser,
    getUserById,
    createUser,
    updateUser,
    getOrdersDetails,
  }
}

export default useUserService
