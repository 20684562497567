import {AxiosResponse} from 'axios'
import useAxiosInstance from '../../../utils/interceptors'

const GET_ACTIVE_PHONE_INFO = `/conversation-log/phone-info/`
const GET_STATISTICS_DETAILS = '/conversation-log/dashboard_statistcs/'

export const useDashboardService = () => {
  const axiosInstance = useAxiosInstance()

  const getActiveCallInfo = (agentType: string): Promise<any> => {
    return axiosInstance
      .get(`${GET_ACTIVE_PHONE_INFO}?agent=${agentType}`)
      .then((d: AxiosResponse<any>) => d.data)
  }

  const getStatisticsDetails = (query: string): Promise<any> => {
    return axiosInstance
      .get(`${GET_STATISTICS_DETAILS}?${query}`)
      .then((d: AxiosResponse<any>) => d.data)
  }

  return {
    getActiveCallInfo,
    getStatisticsDetails,
  }
}

export default useDashboardService
