/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'
import svgInfoIcon from '../../../assets/images/gen075.svg'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
  count?: string
}

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  count,
}) => {
  return (
    <a href='#' className={`card bg-${color} hoverable ${className}`}>
      <div className='card-body px-5'>
        <div className='d-flex align-items-center gap-3'>
          <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-2x ms-n1`} />
          <div className={`fw-bold text-${descriptionColor} fs-1`}>{count}</div>
        </div>
        <div
          className={`text-${titleColor} mt-5 fw-bold fs-3 mb-2 d-flex align-items-center gap-1`}
        >
          {title}
        </div>
      </div>
    </a>
  )
}

export {StatisticsWidget5}
