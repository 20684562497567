/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Call} from '../../core/_models'

type Props = {
  user: Call
}

const UserInfoCell: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <a href='#' className='text-gray-600  mb-1'>
          {user.session_id}
        </a>
      </div>
    </div>
  )
}

export {UserInfoCell}
