/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {useContext} from 'react'
import {useQueryResponse, useQueryResponseLoading} from '../../core/CompletedQueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {loginContext} from '../../../../../context/context'

type UsersTableProps = {
  currentPagination: any
  setCurrentPagination: any // Use appropriate type instead of `any` if you have a model/interface for the data
}

const UsersListPagination: React.FC<UsersTableProps> = ({
  currentPagination,
  setCurrentPagination,
}) => {
  const useQueryResponsePagination = (): any => {
    const defaultPaginationState: any = {
      page: 1,
      page_size: 10,
      page_count: 1,
      count: 0,
      links: [],
    }

    const {response} = useQueryResponse()

    if (!currentPagination || !currentPagination.results || !currentPagination.count) {
      return defaultPaginationState
    }

    const {page_number, page_size, count}: any = currentPagination

    // Calculate the total number of pages
    const page_count = Math.ceil(count / page_size)

    // Generate pagination links dynamically based on the page count
    const links = Array.from({length: page_count}, (_, i) => ({
      label: `${i + 1}`,
      page: i + 1,
    }))

    return {
      page: page_number,
      page_size,
      page_count,
      count,
      links,
    }
  }

  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()

  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    setCurrentPagination((prevState: any) => ({
      ...prevState,
      page_number: page,
    }))

    updateState({page, page_size: pagination.page_size || 10})
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            {/* Previous Button */}
            <li
              className={clsx('page-item', {
                disabled: pagination.page === 1 || isLoading,
              })}
            >
              <a
                className='page-link'
                onClick={() => updatePage(pagination.page - 1)}
                style={{cursor: 'pointer'}}
              >
                Previous
              </a>
            </li>

            {/* Page Numbers */}
            {pagination.links.map((link: any) => (
              <li
                key={link.page}
                className={clsx('page-item', {
                  active: pagination.page === link.page,
                  disabled: isLoading,
                })}
              >
                <a
                  className='page-link'
                  onClick={() => updatePage(link.page)}
                  style={{cursor: 'pointer'}}
                >
                  {link.label}
                </a>
              </li>
            ))}

            {/* Next Button */}
            <li
              className={clsx('page-item', {
                disabled: pagination.page === pagination.page_count || isLoading,
              })}
            >
              <a
                className='page-link'
                onClick={() => updatePage(pagination.page + 1)}
                style={{cursor: 'pointer'}}
              >
                Next
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {UsersListPagination}
