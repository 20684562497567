import React from 'react'
import {FC} from 'react'

type Props = {
  payment_done_time?: string
}

const TransactionTimeCell: FC<Props> = ({payment_done_time}) => {
  const formattedTime = payment_done_time ? new Date(payment_done_time).toLocaleTimeString() : ''

  return <div className='text-gray-800 text-hover-primary mb-1'>{formattedTime}</div>
}

export {TransactionTimeCell}
