import React from 'react'
import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import SessionDetails from './components/SessionDetails'

const SessionDetailsPage = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'HOSPITALITY.AGENT.SESSION.DETAILS.TITLE'})}
      </PageTitle>
      <SessionDetails />
    </>
  )
}

export default SessionDetailsPage
