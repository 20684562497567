import React from 'react'
import ReactFlow, {Background, Controls, Edge, Node} from 'reactflow'
import 'reactflow/dist/style.css'
import {FaRobot, FaUser, FaCheckCircle, FaQuestionCircle} from 'react-icons/fa'

// Custom Node Component
const CustomNode = ({data}: {data: any}) => {
  return (
    <div
      style={{
        padding: '10px',
        backgroundColor: '#f0f8ff',
        borderRadius: '5px',
        border: '1px solid #ccc',
        color: 'black',
        textAlign: 'center',
      }}
    >
      <div>{data.icon}</div>
      <strong>{data.label}</strong>
    </div>
  )
}

// Node Definitions with Custom Styles and Icons
const initialNodes: Node[] = [
  {
    id: '1',
    type: 'custom',
    data: {label: 'Start', icon: <FaCheckCircle color='#4CAF50' size={24} />},
    position: {x: 100, y: 0},
  },
  {
    id: '2',
    type: 'custom',
    data: {label: 'User Initiates Conversation', icon: <FaUser color='#2196F3' size={24} />},
    position: {x: 300, y: 100},
  },
  {
    id: '3',
    type: 'custom',
    data: {label: 'AI Greets User', icon: <FaRobot color='#FF9800' size={24} />},
    position: {x: 500, y: 50},
  },
  {
    id: '4',
    type: 'custom',
    data: {label: 'User Asks for Assistance', icon: <FaQuestionCircle color='#9C27B0' size={24} />},
    position: {x: 700, y: 150},
  },
  {
    id: '5',
    type: 'custom',
    data: {label: 'AI Provides Options', icon: <FaRobot color='#FF9800' size={24} />},
    position: {x: 900, y: 100},
  },
  {
    id: '6',
    type: 'custom',
    data: {label: 'User Selects Option', icon: <FaUser color='#2196F3' size={24} />},
    position: {x: 1100, y: 200},
  },
  {
    id: '7',
    type: 'custom',
    data: {label: 'AI Assists with Request', icon: <FaRobot color='#FF9800' size={24} />},
    position: {x: 1300, y: 150},
  },
  {
    id: '8',
    type: 'custom',
    data: {label: 'AI Confirms Satisfaction', icon: <FaCheckCircle color='#4CAF50' size={24} />},
    position: {x: 1500, y: 250},
  },
  {
    id: '9',
    type: 'custom',
    data: {label: 'End', icon: <FaCheckCircle color='#4CAF50' size={24} />},
    position: {x: 1700, y: 200},
  },
]

const initialEdges: Edge[] = [
  {id: 'e1-2', source: '1', target: '2', type: 'smoothstep', animated: true},
  {id: 'e2-3', source: '2', target: '3', type: 'smoothstep', animated: true},
  {id: 'e3-4', source: '3', target: '4', type: 'smoothstep', animated: true},
  {id: 'e4-5', source: '4', target: '5', type: 'smoothstep', animated: true},
  {id: 'e5-6', source: '5', target: '6', type: 'smoothstep', animated: true},
  {id: 'e6-7', source: '6', target: '7', type: 'smoothstep', animated: true},
  {id: 'e7-8', source: '7', target: '8', type: 'smoothstep', animated: true},
  {id: 'e8-9', source: '8', target: '9', type: 'smoothstep', animated: true},
]

// Register the custom node type
const nodeTypes = {
  custom: CustomNode,
}

const FlowChart: React.FC = () => {
  return (
    <div className='container mb-5 card p-5'>
      <h2 className='mb-4'>Hospital Agent Flowchart</h2>
      <div
        style={{
          height: '300px',
          width: '100%',
          border: '1px solid #ddd',
          borderRadius: '8px',
          padding: '10px',
        }}
      >
        <ReactFlow nodes={initialNodes} edges={initialEdges}>
          <Background />
          {/* <Controls /> */}
        </ReactFlow>
      </div>
    </div>
  )
}

export default FlowChart
