// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {Transaction} from '../../core/_models'
import {TransactionTimeCell} from './TransactionTimeCell'

const usersColumns: ReadonlyArray<Column<Transaction>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Loan Id' className='min-w-125px' />
    ),
    id: 'loan_id',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Account Number' className='min-w-150px' />
    ),
    id: 'account_number',
    accessor: (row) => row.account?.account_number,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Customer Name' className='min-w-125px' />
    ),
    id: 'name',
    accessor: (row) => row.account?.customer?.name,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Loan Amount' className='min-w-125px' />
    ),
    id: 'loan_amount',
    accessor: (row) => row?.loan_amount,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Emi Amount' className='min-w-125px' />
    ),
    id: 'emi_amount',
    accessor: (row) => row?.emi_amount,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Emi Due Date' className='min-w-125px' />
    ),
    id: 'emi_due_date',
    accessor: (row) => row?.emi_due_date,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Outstanding Amount' className='min-w-125px' />
    ),
    id: 'outstanding_amount',
    accessor: (row) => row?.outstanding_amount,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Loan Type' className='min-w-125px' />
    ),
    id: 'loan_type',
    accessor: (row) => row?.loan_type,
  },
]

export {usersColumns}
