import {FC} from 'react'

type Props = {
  to?: string
}

const UserLastLoginCell: FC<Props> = ({to}) => (
  <div className='text-gray-800 mb-1'>{to ? to : '-'}</div>
)

export {UserLastLoginCell}
