import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {CompletedSessionsListWrapper} from './completed-sessions-list/CompletedSessionsList'

type UsersTableProps = {
  data: any[]
  currentPagination: any
  setCurrentPagination: any // Use appropriate type instead of `any` if you have a model/interface for the data
}

const CompletedSessionsPage: React.FC<UsersTableProps> = ({
  data,
  currentPagination,
  setCurrentPagination,
}) => {
  const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ORDERS_DETAILS_TITLE'})}</PageTitle> */}
      <CompletedSessionsListWrapper
        data={data}
        currentPagination={currentPagination}
        setCurrentPagination={setCurrentPagination}
      />
    </>
  )
}

export default CompletedSessionsPage
