import {useAxiosInstance} from '../../../../../utils/interceptors'
import {BookingQueryResponse} from './_models'
import {AxiosResponse} from 'axios'

const GET_ARABIC_ORDERS_URL = `/ar/bookings/`

export const useUserService = () => {
  const axiosInstance = useAxiosInstance()

  const getArabicOrdersDetails = (query: string): Promise<BookingQueryResponse> => {
    return axiosInstance
      .get(`${GET_ARABIC_ORDERS_URL}?${query}`)
      .then((d: AxiosResponse<BookingQueryResponse>) => d.data)
  }

  return {
    getArabicOrdersDetails,
  }
}

export default useUserService
