import React, {useContext, useEffect, useState} from 'react'
import {MapContainer, TileLayer, Marker, Popup, useMap} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import {LatLngExpression} from 'leaflet'
import L from 'leaflet'
import callImage from '../../../assets/images/call_icon.png'
import useDashboardService from '../../../../app/pages/dashboard/core/_requests'
import {loginContext} from '../../../../app/context/context'

// Call icon URL
const callIconUrl = callImage

// Create a custom icon instance
const callIcon = new L.Icon({
  iconUrl: callIconUrl,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
})

const SetViewOnZoom: React.FC<{center: LatLngExpression; zoom: number}> = ({center, zoom}) => {
  const map = useMap()
  map.setView(center, zoom)
  return null
}

const UsersLocateMap: React.FC = () => {
  const [callDetails, setCallDetails] = useState([])
  const center: LatLngExpression = [37.7749, -122.4194]
  const zoom = 12
  const {getActiveCallInfo} = useDashboardService()
  const {agentType} = useContext(loginContext)

  const fetchActiveCallInfo = async () => {
    try {
      const response = await getActiveCallInfo(agentType)
      setCallDetails(response?.active_calls)
    } catch (error) {
      console.error('Error fetching completed sessions:', error)
    }
  }

  useEffect(() => {
    fetchActiveCallInfo()
  }, [agentType])

  // Group call details by location
  const groupedCalls = callDetails.reduce((acc: any, agent: any) => {
    const key = `${agent.from_country_info?.latitude},${agent.from_country_info?.longitude}`
    if (!acc[key]) {
      acc[key] = {
        position: [agent.from_country_info?.latitude, agent.from_country_info?.longitude],
        numbers: [],
      }
    }
    acc[key].numbers.push(agent.from_number)
    return acc
  }, {})

  return (
    <div className='border mb-5 p-3 rounded-3 card' style={{height: '490px'}}>
      <span className='fs-5 fw-bold'>Users by Location</span>
      <div className='mt-5'>
        <MapContainer style={{height: '430px', width: '100%'}}>
          <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
          <SetViewOnZoom center={center} zoom={zoom} />
          {Object.values(groupedCalls).map((location: any, index) => (
            <Marker key={index} position={location.position} icon={callIcon}>
              <Popup>
                {location.numbers.map((number: string, i: number) => (
                  <div key={i}>{number}</div>
                ))}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </div>
  )
}

export default UsersLocateMap
