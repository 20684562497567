import {useAxiosInstance} from '../../../../../utils/interceptors'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, UsersQueryResponse, LoansQueryResponse} from './_models'
import {AxiosResponse} from 'axios'

const USER_URL = `/user`
const GET_USERS_URL = `/users/query`
const GET_LOAN_DETIALS_URL = `/banking/loan-details/`

export const useLoadDetailsService = () => {
  const axiosInstance = useAxiosInstance()
  const getUsers = (query: string): Promise<UsersQueryResponse> => {
    return axiosInstance
      .get(`${GET_USERS_URL}?${query}`)
      .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  }

  const getUserById = (id: ID): Promise<User | undefined> => {
    return axiosInstance
      .get(`${USER_URL}/${id}`)
      .then((response: AxiosResponse<Response<User>>) => response.data)
      .then((response: Response<User>) => response.data)
  }

  const createUser = (user: User): Promise<User | undefined> => {
    return axiosInstance
      .put(USER_URL, user)
      .then((response: AxiosResponse<Response<User>>) => response.data)
      .then((response: Response<User>) => response.data)
  }

  const updateUser = (user: User): Promise<User | undefined> => {
    return axiosInstance
      .post(`${USER_URL}/${user.id}`, user)
      .then((response: AxiosResponse<Response<User>>) => response.data)
      .then((response: Response<User>) => response.data)
  }

  const deleteUser = (userId: ID): Promise<void> => {
    return axiosInstance.delete(`${USER_URL}/${userId}`).then(() => {})
  }

  const getLoanDetails = (query: string): Promise<LoansQueryResponse> => {
    return axiosInstance
      .get(`${GET_LOAN_DETIALS_URL}?${query}`)
      .then((d: AxiosResponse<LoansQueryResponse>) => d.data)
  }
  return {getUsers, deleteUser, getUserById, createUser, updateUser, getLoanDetails}
}

export default useLoadDetailsService
