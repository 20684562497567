/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import React from 'react'
import {TrackingInfo} from '../../core/_models'

type Props = {
  user: TrackingInfo
}

const WarehouseArrivalDate: FC<Props> = ({user}) => {
  // Check if appointment_date exists and format it, otherwise display a fallback value
  const formattedDate = user.scheduled_warehouse_arrival
    ? new Date(user.scheduled_warehouse_arrival).toISOString().split('T')[0]
    : 'N/A' // or any fallback message

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span>{formattedDate}</span>
      </div>
    </div>
  )
}

export {WarehouseArrivalDate}
