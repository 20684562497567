import React, {useContext, useEffect, useMemo, useState} from 'react'
import FlowChart from './FlowChart'
import AnalyticsChart from './AnalyticsChart'
import {ActiveSessionsTable} from '../active-sessions/active-sessions-list/table/ActiveSessionsTable'
import {loginContext} from '../../context/context'
import useUserService from '../completed-sessions/completed-sessions-list/core/_requests'
import useHospitalityAgentService from '../active-sessions/active-sessions-list/core/_requests'
import {CompletedSessionsTable} from '../completed-sessions/completed-sessions-list/table/CompletedSessionsTable'
import {stringifyRequestQuery} from '../../../_metronic/helpers'
import {useQueryRequest} from '../completed-sessions/completed-sessions-list/core/QueryRequestProvider'
import ActiveSessionsPage from '../active-sessions/ActiveSessionsPage'
import CompletedSessionsPage from '../completed-sessions/CompletedSessionsPage'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'

const HospitalAgentList: React.FC = () => {
  const {getActiveSessions} = useHospitalityAgentService()
  const {getSessionsHistory} = useUserService()
  const [activeSessions, setActiveSessions] = useState<any[]>([])
  const [completedSessions, setCompletedSessions] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const {hospitalityPagination, setHospitalityPagination, filterData} = useContext(loginContext)
  const intl = useIntl()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(filterData))
  const updatedQuery = useMemo(() => stringifyRequestQuery(filterData), [filterData])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  useEffect(() => {
    // Fetch active sessions data when the component mounts
    const fetchActiveSessions = async () => {
      try {
        const response = await getActiveSessions('hospitality_agent', '1', '10')
        setActiveSessions(response.data.results)
      } catch (error) {
        console.error('Error fetching active sessions:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchActiveSessions()
  }, [])

  const fetchCompletedSessions = async () => {
    setLoading(true) // Ensure loading state is set correctly
    try {
      const response = await getSessionsHistory('hospitality_agent', query)
      setCompletedSessions(response.data.results)
      setHospitalityPagination(response?.data) // Update pagination context
    } catch (error) {
      console.error('Error fetching completed sessions:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchCompletedSessions()
  }, [query])

  // const {getSessionsHistory} = useUserService()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'HOSPITALITY.AGENT.TITLE'})}</PageTitle>

      <div className='hospitality-agent-section'>
        {/* Start flow chart */}
        <div className='flow-chart-section'>
          <FlowChart />
        </div>
        {/* End flow chart */}

        {/* Start active sessions table */}
        <div className='active-sessions-table mb-5 mt-5'>
          <ActiveSessionsPage data={activeSessions} />
        </div>
        {/* End active sessions table */}
        {/* Start sessions history table */}
        <div className='sessions-history-table mt-5'>
          <CompletedSessionsPage
            data={completedSessions}
            currentPagination={hospitalityPagination}
            setCurrentPagination={setHospitalityPagination}
          />
        </div>
        {/* End sessions history table */}
        {/* Start Analytics chart */}
        <div className='analytics-chart-section'>
          <AnalyticsChart />
        </div>
        {/* End Analytics chart */}
      </div>
    </>
  )
}

export default HospitalAgentList
