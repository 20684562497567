/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Transaction} from '../../core/_models'
import React from 'react'

type Props = {
  user: any
}

const GuestNameCell: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
          {user.guest_full_name_ar}
        </a>
      </div>
    </div>
  )
}

export {GuestNameCell}
