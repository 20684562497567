import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'
import {useAuth} from '../modules/auth'

const API_URL = process.env.REACT_APP_API_URL

export const useAxiosInstance = () => {
  const {logout} = useAuth()

  const axiosInstance: AxiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  // Request Interceptor
  const requestInterceptor = (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('authToken')
    if (token) {
      if (!config.headers) {
        config.headers = {}
      }
      config.headers.Authorization = `Token ${token}`
    }

    return config
  }

  const requestErrorInterceptor = (error: any) => {
    return Promise.reject(error)
  }

  // Response Interceptor
  const responseInterceptor = (response: AxiosResponse) => {
    return response
  }

  const responseErrorInterceptor = (error: any) => {
    if (error.response?.status === 401) {
      logout()
    }
    return Promise.reject(error)
  }

  // Applying the interceptors
  axiosInstance.interceptors.request.use(requestInterceptor, requestErrorInterceptor)
  axiosInstance.interceptors.response.use(responseInterceptor, responseErrorInterceptor)

  return axiosInstance
}

export default useAxiosInstance
