import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  StatisticsWidget5,
  ListsWidget1,
} from '../../../_metronic/partials/widgets'
import {Session} from 'inspector'
import SessionList from '../../../_metronic/partials/widgets/lists/SessionList'
import SessionByChannelList from '../../../_metronic/partials/widgets/lists/SessionByChannelList'
import TopIntentsList from '../../../_metronic/partials/widgets/lists/TopIntentsList'
import FilterOption from '../../../_metronic/partials/widgets/lists/FilterOption'
import UsersLocateMap from '../../../_metronic/partials/widgets/lists/UsersLocateMap'
import {TotalAgentAnalyticsGraph} from '../../../_metronic/partials/widgets/lists/TotalAgentAnalyticsGraph'
import useDashboardService from './core/_requests'
import {useContext, useEffect, useMemo, useState} from 'react'
import {stringifyRequestQuery} from '../../../_metronic/helpers'
import {loginContext} from '../../context/context'

const DashboardPage = () => {
  const {getStatisticsDetails} = useDashboardService()
  const {filterDashboardData} = useContext(loginContext)
  const [query, setQuery] = useState<string>(stringifyRequestQuery(filterDashboardData))
  const [statisticsData, setStatisticsData] = useState<any>(null)

  const updatedQuery = useMemo(
    () => stringifyRequestQuery(filterDashboardData),
    [filterDashboardData]
  )

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const fetchDashboardStaticsDetails = async () => {
    try {
      const response = await getStatisticsDetails(query)
      setStatisticsData(response)
    } catch (error) {
      console.error('Error fetching completed sessions:', error)
    }
  }

  useEffect(() => {
    fetchDashboardStaticsDetails()
  }, [query])

  return (
    <>
      {/* begin::Row */}
      <div className='row'>
        <div className='col-xl-12'>
          <FilterOption />
        </div>
      </div>
      {/* end::Row */}
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3 p-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen075.svg'
            color='primary'
            iconColor='white'
            title='Active Contacts'
            description=''
            titleColor='white'
            descriptionColor='white'
            count={statisticsData?.active_calls_count}
          />
        </div>

        <div className='col-xl-3 p-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen076.svg'
            color='success'
            iconColor='white'
            title='Total Sessions'
            description=''
            titleColor='white'
            descriptionColor='white'
            count={statisticsData?.total_calls_count}
          />
        </div>

        <div className='col-xl-3 p-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen077.svg'
            color='danger'
            iconColor='white'
            title='Understood Messages'
            description=''
            titleColor='white'
            descriptionColor='white'
            count={statisticsData?.understood_mesages}
          />
        </div>

        <div className='col-xl-3 p-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen078.svg'
            color='primary'
            iconColor='white'
            title='Positive Ratings'
            description=''
            titleColor='white'
            descriptionColor='white'
            count={statisticsData?.positive_ratings}
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row'>
        <div className='col-xl-12'>
          <SessionList />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row'>
        <div className='col-xl-6'>
          <SessionByChannelList />
        </div>
        <div className='col-xl-6'>
          <TopIntentsList />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row'>
        <div className='col-xl-12'>
          <UsersLocateMap />
        </div>
      </div>
      {/* end::Row */}

      <div className='row'>
        <div className='col-xl-12'>
          <TotalAgentAnalyticsGraph />
        </div>
      </div>
      {/* begin::Row */}
      <div className='row'>
        <div className='col-xl-6'>
          <SessionByChannelList />
        </div>
        <div className='col-xl-6'>
          <TopIntentsList />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row'>
        <div className='col-xl-12'>
          <SessionList />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row'>
        <div className='col-xl-12'>
          <SessionList />
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
