import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'

const UsersListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [status, setStatus] = useState<string[]>([])
  const [filterType, setFilterType] = useState<string[]>([])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setStatus([])
    setFilterType([])
    updateState({filter: undefined, ...initialQueryState})
  }

  const filterData = () => {
    updateState({
      filter: {status, filter_type: filterType},
      ...initialQueryState,
    })
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value, checked} = e.target
    setStatus((prevStatus) =>
      checked ? [...prevStatus, value] : prevStatus.filter((status) => status !== value)
    )
  }

  const handleTypeCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value, checked} = e.target
    setFilterType((prevStatus) =>
      checked ? [...prevStatus, value] : prevStatus.filter((status) => status !== value)
    )
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      <button
        type='button'
        disabled={isLoading}
        onClick={resetData}
        className='btn btn-secondary btn-active-light-primary fw-bold me-2 px-6'
        data-kt-menu-dismiss='true'
        data-kt-user-table-filter='reset'
      >
        Reset
      </button>
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Checkbox group */}
          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>Account Status:</label>
            <div className='d-flex gap-5'>
              <div className='form-check mb-3 d-flex align-items-center gap-3'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='statusActive'
                  value='Active'
                  checked={status.includes('Active')}
                  onChange={handleCheckboxChange}
                  style={{width: '20px', height: '20px'}}
                />
                <label className='form-check-label fs-4' htmlFor='statusActive'>
                  Active
                </label>
              </div>
              <div className='form-check mb-3 d-flex align-items-center gap-3'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='statusInactive'
                  value='Inactive'
                  checked={status.includes('Inactive')}
                  onChange={handleCheckboxChange}
                  style={{width: '20px', height: '20px'}}
                />
                <label className='form-check-label fs-4 ' htmlFor='statusInactive'>
                  Inactive
                </label>
              </div>
            </div>
          </div>
          {/* end::Checkbox group */}

          {/* begin::Checkbox group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Account Type:</label>
            <div className='form-check mb-3 d-flex align-items-center gap-3'>
              <input
                className='form-check-input'
                type='checkbox'
                id='statusSavings'
                value='Savings'
                checked={filterType.includes('Savings')}
                onChange={handleTypeCheckboxChange}
                style={{width: '20px', height: '20px'}}
              />
              <label className='form-check-label fs-4' htmlFor='statusSavings'>
                Savings
              </label>
            </div>
            <div className='form-check mb-3 d-flex align-items-center gap-3'>
              <input
                className='form-check-input'
                type='checkbox'
                id='statusChecking'
                value='Checking'
                checked={filterType.includes('Checking')}
                onChange={handleTypeCheckboxChange}
                style={{width: '20px', height: '20px', borderColor: 'lightgrey'}}
              />
              <label className='form-check-label fs-4' htmlFor='statusChecking'>
                Checking
              </label>
            </div>
            <div className='form-check mb-3 d-flex align-items-center gap-3'>
              <input
                className='form-check-input'
                type='checkbox'
                id='statusBusiness'
                value='Business'
                checked={filterType.includes('Business')}
                onChange={handleTypeCheckboxChange}
                style={{width: '20px', height: '20px'}}
              />
              <label className='form-check-label fs-4' htmlFor='statusBusiness'>
                Business
              </label>
            </div>
          </div>
          {/* end::Checkbox group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-secondary btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {UsersListFilter}
