import React, {FC, useState} from 'react'
import PackagePage from '../../package-details/PackagePage'

const LogisticsMetaDataList: FC = () => {
  const [currentStep, setCurrentStep] = useState(0)

  // Define the steps and corresponding components
  const steps = [{title: 'Packages Details', component: <PackagePage />}]

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='stepper stepper-links d-flex flex-column' id='kt_create_account_stepper'>
          <div className='stepper-nav mb-5 justify-content-start m-0'>
            {steps.map((step, index) => (
              <div
                key={index}
                className={`stepper-item ${currentStep === index ? 'current' : ''}`}
                data-kt-stepper-element='nav'
                onClick={() => setCurrentStep(index)}
                style={{cursor: 'pointer'}}
              >
                <h3 className='stepper-title'>{step.title}</h3>
              </div>
            ))}
          </div>

          <div>{steps[currentStep].component}</div>
        </div>
      </div>
    </div>
  )
}

export default LogisticsMetaDataList
