/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {LoanResponse} from '../../core/_models'

type Props = {
  user: LoanResponse
}

const UserInfoCell: FC<Props> = ({user}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <a href='#' className='text-gray-600 text-hover-primary mb-1'>
          {user.id}
        </a>
      </div>
    </div>
  )
}

export {UserInfoCell}
