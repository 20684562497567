import {FC} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import HospitalAgentList from '../LogisticsAgent'
import LogisticsAgent from '../LogisticsAgent'

const LogisticsAgentPage: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'LOGISTICS.AGENT.TITLE'})}</PageTitle>
      <LogisticsAgent />
    </>
  )
}

export default LogisticsAgentPage
