import React, {useState} from 'react'
import {Bar} from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {exampleData} from '../../utils/data/StaticsData'

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const AnalyticsChart: React.FC = () => {
  const [filter, setFilter] = useState<'day' | 'week' | 'month' | 'year'>('day')

  const handleFilterChange = (newFilter: 'day' | 'week' | 'month' | 'year') => {
    setFilter(newFilter)
  }

  const data = exampleData[filter]

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            return `${context.dataset.label}: ${context.raw}`
          },
        },
      },
    },
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
  }

  // Modify the datasets for custom styles
  data.datasets.forEach((dataset: any) => {
    if (dataset.label === 'Bot Handled') {
      dataset.borderColor = 'rgba(75, 192, 192, 1)'
      dataset.borderWidth = 1
    } else if (dataset.label === 'Human Transferred') {
      dataset.borderColor = 'rgba(255, 99, 132, 1)'
      dataset.borderWidth = 1
    }
  })

  return (
    <div className='container card p-5' style={{marginTop: '30px'}}>
      <div className='d-flex flex-column flex-md-row flex-sm-row flex-lg-row align-items-start justify-content-between mb-4'>
        <h2>Agent Interaction Statistics</h2>
        <div className='mb-4 d-flex mt-3 mt-lg-0 mt-sm-0 mt-md-0'>
          <button
            className={`btn me-2 py-2 px-4 ${
              filter === 'day' ? 'btn-primary active' : 'btn-secondary'
            }`}
            onClick={() => handleFilterChange('day')}
          >
            Day
          </button>
          <button
            className={`btn mx-2 py-2 px-4 ${
              filter === 'week' ? 'btn-primary active' : 'btn-secondary'
            }`}
            onClick={() => handleFilterChange('week')}
          >
            Week
          </button>
          <button
            className={`btn mx-2 py-2 px-4 ${
              filter === 'month' ? 'btn-primary active' : 'btn-secondary'
            }`}
            onClick={() => handleFilterChange('month')}
          >
            Month
          </button>
          <button
            className={`btn mx-2 py-2 px-4 ${
              filter === 'year' ? 'btn-primary active' : 'btn-secondary'
            }`}
            onClick={() => handleFilterChange('year')}
          >
            Year
          </button>
        </div>
      </div>

      <div className='chart-container'>
        <Bar data={data} options={options} />
      </div>
    </div>
  )
}

export default AnalyticsChart
