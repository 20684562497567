import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik, FormikHelpers} from 'formik'
import useAuthService from '../../core/_requests' // Import the resetPassword function
import {Link, useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'

// Validation schema for the form
const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, 'Minimum 6 characters')
    .max(50, 'Maximum 50 characters')
    .required('New password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required'),
})

// Initial values for the form
const initialValues = {
  newPassword: '',
  confirmPassword: '',
}

// TypeScript interface for URL parameters
type Params = {
  token: string
  uid?: string // optional parameter
}

const ResetPassword: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [status, setStatus] = useState<string | null>(null)
  const {token, uid} = useParams<Params>()
  const navigate = useNavigate()
  const {resetPassword} = useAuthService()

  // Formik setup for handling form submission and validation
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, {setSubmitting}: FormikHelpers<typeof initialValues>) => {
      setLoading(true)
      try {
        // Ensure token and uid are defined
        if (token && uid) {
          const response = await resetPassword(values.newPassword, token, uid)
          const data: any = response
          toast.success(data.data.message)
          navigate('/login')
        } else {
          // Handle the case where token or uid is undefined
          console.error('Token or UID is undefined')
          setStatus('Error resetting password')
        }
      } catch (error) {
        console.error(error)
        setStatus('Error resetting password')
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_reset_password_form'
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Reset Password</h1>
      </div>

      {status && (
        <div className='mb-lg-15 alert alert-info'>
          <div className='alert-text font-weight-bold'>{status}</div>
        </div>
      )}

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>New Password</label>
        <input
          placeholder='New Password'
          {...formik.getFieldProps('newPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.newPassword && formik.errors.newPassword},
            {
              'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
            }
          )}
          type='password'
          name='newPassword'
          autoComplete='off'
        />
        {formik.touched.newPassword && formik.errors.newPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.newPassword}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Confirm Password</label>
        <input
          placeholder='Confirm Password'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
          type='password'
          name='confirmPassword'
          autoComplete='off'
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_reset_password_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Reset Password</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <div className='text-end'>
        <Link to='/login' className='link-primary fs-6 fw-bolder' style={{marginLeft: '5px'}}>
          Back to Login
        </Link>
      </div>
    </form>
  )
}

export default ResetPassword
