import {useAxiosInstance} from '../../../../../utils/interceptors'
import {TransactionsQueryResponse} from './_models'
import {AxiosResponse} from 'axios'

const GET_ARABIC_TRANSACTION_URL = `/ar/payments/`

export const useTransactionService = () => {
  const axiosInstance = useAxiosInstance()

  const getArabicTransactionsDetails = (query: string): Promise<TransactionsQueryResponse> => {
    return axiosInstance
      .get(`${GET_ARABIC_TRANSACTION_URL}?${query}`)
      .then((d: AxiosResponse<TransactionsQueryResponse>) => d.data)
  }
  return {getArabicTransactionsDetails}
}

export default useTransactionService
