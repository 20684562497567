import {FC} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {TransactionListWrapper} from './arabic-transaction-list/ArabicTransactionList'

const TransactionPage: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'TRANSACTION_DETAILS_TITLE'})}
      </PageTitle>
      <TransactionListWrapper />
    </>
  )
}

export default TransactionPage
