// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {Booking} from '../../core/_models'
import {GuestNameCell} from '../../../../transaction-details/transaction-list/table/columns/GuestNameCell'

const usersColumns: ReadonlyArray<Column<Booking>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Bookng Id' className='min-w-125px' />
    ),
    id: 'id',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Guest Name' className='min-w-125px' />
    ),
    id: 'full_name',
    accessor: (row) => row.guest?.full_name, // Access the full_name from guest
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Hotel Name' className='min-w-125px' />
    ),
    id: 'name',
    accessor: (row) => row.room?.hotel.name, // Access the full_name from guest
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Room Type' className='min-w-125px' />
    ),
    id: 'room_type',
    accessor: (row) => row.room?.room_type, // Access the full_name from guest
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Amount' className='min-w-125px' />
    ),
    id: 'total_amount',
    accessor: (row) => row?.total_amount, // Access the full_name from guest
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Check In Date' className='min-w-125px' />
    ),
    id: 'check_in_date',
    accessor: (row) => row?.check_in_date, // Access the full_name from guest
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Check Out Date' className='min-w-125px' />
    ),
    id: 'check_out_date',
    accessor: (row) => row?.check_out_date, // Access the full_name from guest
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    accessor: (row) => row?.status, // Access the full_name from guest
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {usersColumns}
