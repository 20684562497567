import React, {useContext, useEffect, useMemo, useState} from 'react'
import FlowChart from './LogisticsAgent/components/FlowChart'
import AnalyticsChart from './LogisticsAgent/components/AnalyticsChart'
import {UsersTable} from './LogisticsAgent/table/UsersTable'
import useHospitalityAgentService from '../active-sessions/active-sessions-list/core/_requests'
import useUserService from '../completed-sessions/completed-sessions-list/core/_requests'
import {ActiveSessionsTable} from '../active-sessions/active-sessions-list/table/ActiveSessionsTable'
import {CompletedSessionsTable} from '../completed-sessions/completed-sessions-list/table/CompletedSessionsTable'
import {loginContext} from '../../context/context'
import ActiveSessionsPage from '../active-sessions/ActiveSessionsPage'
import CompletedSessionsPage from '../completed-sessions/CompletedSessionsPage'
import {stringifyRequestQuery} from '../../../_metronic/helpers'

const LogisticsAgent: React.FC = () => {
  const {getActiveSessions} = useHospitalityAgentService()
  const {getSessionsHistory} = useUserService()
  const [activeSessions, setActiveSessions] = useState<any[]>([])
  const [completedSessions, setCompletedSessions] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const {setLogisticsPagination, logisticsPagination, filterData} = useContext(loginContext)
  const [query, setQuery] = useState<string>(stringifyRequestQuery(filterData))
  const updatedQuery = useMemo(() => stringifyRequestQuery(filterData), [filterData])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  useEffect(() => {
    // Fetch active sessions data when the component mounts
    const fetchActiveSessions = async () => {
      try {
        const response = await getActiveSessions('logistics_agent', '1', '10')
        setActiveSessions(response.data.results)
      } catch (error) {
        console.error('Error fetching active sessions:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchActiveSessions()
  }, [])

  const fetchCompletedSessions = async () => {
    setLoading(true) // Ensure loading state is set correctly
    try {
      const response = await getSessionsHistory('logistics_agent', query)
      setCompletedSessions(response.data.results)
      setLogisticsPagination(response?.data) // Update pagination context
    } catch (error) {
      console.error('Error fetching completed sessions:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchCompletedSessions()
  }, [query])

  return (
    <div className='hospitality-agent-section'>
      {/* Start flow chart */}
      <div className='flow-chart-section'>
        <FlowChart />
      </div>
      {/* End flow chart */}

      {/* Start active sessions table */}
      <div className='active-sessions-table mb-5 mt-5'>
        <ActiveSessionsPage data={activeSessions} />
      </div>
      {/* End active sessions table */}
      {/* Start sessions history table */}
      <div className='sessions-history-table mt-5'>
        <CompletedSessionsPage
          data={completedSessions}
          currentPagination={logisticsPagination}
          setCurrentPagination={setLogisticsPagination}
        />
      </div>
      {/* End sessions history table */}

      {/* Start Analytics chart */}
      <div className='analytics-chart-section'>
        <AnalyticsChart />
      </div>
      {/* End Analytics chart */}
    </div>
  )
}

export default LogisticsAgent
