// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {Call} from '../../core/_models'
import {UserLogsCell} from './UserLogsCell'
import {UserFromCell} from './UserFromCell'
import {CallDate} from './CallDate'

const usersColumns: ReadonlyArray<Column<Call>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='ID' className='min-w-125px' />,
    id: 'id',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Date' className='min-w-125px' />,
    id: 'start_date',
    Cell: ({...props}) => <CallDate date={props.data[props.row.index].start_datetime} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='From' className='min-w-125px' />,
    id: 'from',
    Cell: ({...props}) => <UserFromCell from_number={props.data[props.row.index].from_number} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='To' className='min-w-125px' />,
    id: 'to',
    Cell: ({...props}) => <UserLastLoginCell to_number={props.data[props.row.index].to_number} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Durations' className='min-w-125px' />
    ),
    id: 'durations',
    Cell: ({...props}) => (
      <UserTwoStepsCell call_duration={props.data[props.row.index].call_duration} />
    ),
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Logs' className='min-w-125px' />,
    id: 'logs',
    Cell: ({...props}) => <UserLogsCell logs={props.data[props.row.index]} />,
  },
]

export {usersColumns}
