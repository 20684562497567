import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'

const UsersListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [status, setStatus] = useState<string[]>([])
  const [lastLogin, setLastLogin] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setStatus([])
    updateState({filter: undefined, ...initialQueryState})
  }

  const filterData = () => {
    updateState({
      filter: {status, last_login: lastLogin},
      ...initialQueryState,
    })
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value, checked} = e.target
    setStatus((prevStatus) =>
      checked ? [...prevStatus, value] : prevStatus.filter((status) => status !== value)
    )
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      <button
        type='button'
        disabled={isLoading}
        onClick={resetData}
        className='btn btn-secondary btn-active-light-primary fw-bold me-2 px-6'
        data-kt-menu-dismiss='true'
        data-kt-user-table-filter='reset'
      >
        Reset
      </button>
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Checkbox group */}
          <div className='mb-10'>
            <div className='form-check mb-3 d-flex align-items-center gap-3'>
              <input
                className='form-check-input'
                type='checkbox'
                id='statusInTransit'
                value='In Transit'
                checked={status.includes('In Transit')}
                onChange={handleCheckboxChange}
                style={{width: '20px', height: '20px'}}
              />
              <label className='form-check-label fs-4 text-muted' htmlFor='statusInTransit'>
                In Transit
              </label>
            </div>
            <div className='form-check mb-3 d-flex align-items-center gap-3'>
              <input
                className='form-check-input'
                type='checkbox'
                id='statusArrivedAtWarehouse'
                value='Arrived at Warehouse'
                checked={status.includes('Arrived at Warehouse')}
                onChange={handleCheckboxChange}
                style={{width: '20px', height: '20px'}}
              />
              <label
                className='form-check-label fs-4 text-muted'
                htmlFor='statusArrivedAtWarehouse'
              >
                Arrived at Warehouse
              </label>
            </div>
            <div className='form-check mb-3 d-flex align-items-center gap-3'>
              <input
                className='form-check-input'
                type='checkbox'
                id='statusDelivered'
                value='Delivered'
                checked={status.includes('Delivered')}
                onChange={handleCheckboxChange}
                style={{width: '20px', height: '20px'}}
              />
              <label className='form-check-label fs-4 text-muted ' htmlFor='statusDelivered'>
                Delivered
              </label>
            </div>
          </div>
          {/* end::Checkbox group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-secondary btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {UsersListFilter}
