/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {FaBuilding} from 'react-icons/fa'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen025.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      />
      <AsideMenuItem
        to='/hospitality-agent'
        icon='/media/icons/duotune/general/gen070.svg'
        title='Hospitality Agent'
      />
      <AsideMenuItem
        to='/hospitality-arabic-agent'
        icon='/media/icons/duotune/general/gen070.svg'
        title='Hospitality Arabic Agent'
      />
      <AsideMenuItem
        to='/doctor-appointment-agent'
        icon='/media/icons/duotune/general/gen071.svg'
        title='Doctor Appointment Agent'
      />
      <AsideMenuItem
        to='/logistics-agent'
        icon='/media/icons/duotune/general/gen072.svg'
        title='Logistics Agent'
      />
      <AsideMenuItem
        to='/banking-agent'
        icon='/media/icons/duotune/general/gen073.svg'
        title='Banking Agent'
      />
      <AsideMenuItem
        to='/call-logs'
        icon='/media/icons/duotune/general/gen074.svg'
        title='Call logs'
      />
    </>
  )
}
