import {FC, useEffect, useState} from 'react'

type Props = {
  start_datetime?: string // expect ISO string format
}

const formatDuration = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = seconds % 60

  return [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    secs.toString().padStart(2, '0'),
  ].join(':')
}

const UserTwoStepsCell: FC<Props> = ({start_datetime}) => {
  const [duration, setDuration] = useState<number>(0)

  useEffect(() => {
    if (!start_datetime) return

    const startTime = new Date(start_datetime).getTime()

    const updateDuration = () => {
      const now = new Date().getTime()
      const elapsedSeconds = Math.floor((now - startTime) / 1000)
      setDuration(elapsedSeconds)
    }

    // Update duration every second
    const intervalId = setInterval(updateDuration, 1000)

    // Initial call to set the duration immediately
    updateDuration()

    // Clean up interval on component unmount
    return () => clearInterval(intervalId)
  }, [start_datetime])

  return <div className='text-gray-600 mb-1'>{formatDuration(duration)}</div>
}

export {UserTwoStepsCell}
