import {useEffect, useRef, useState} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/CompletedQueryResponseProvider'
import {useLocation} from 'react-router-dom'

const UsersListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [status, setStatus] = useState<string[]>([])
  const [lastLogin, setLastLogin] = useState<string | undefined>()
  const [startDate, setStartDate] = useState<string | undefined>()
  const [endDate, setEndDate] = useState<string | undefined>()
  const [fromNumber, setFromNumber] = useState<number | undefined>()
  const [toNumber, setToNumber] = useState<number | undefined>()
  const dropdownRef = useRef<HTMLDivElement>(null)
  const location = useLocation()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setStatus([])
    setStartDate(undefined)
    setEndDate(undefined)
    setToNumber(undefined)
    setFromNumber(undefined)
    updateState({filter: undefined, ...initialQueryState})
  }

  useEffect(() => {
    resetData()
  }, [location.pathname])

  const filterData = () => {
    // Build filter object based on available state
    const filter = {
      ...(status.length > 0 && {status}),
      ...(lastLogin && {last_login: lastLogin}),
      ...(startDate && {start_date: startDate}),
      ...(endDate && {end_date: endDate}),
      ...(fromNumber !== undefined && {from_number: fromNumber}),
      ...(toNumber !== undefined && {to_number: toNumber}),
    }

    updateState({
      filter,
      ...initialQueryState,
    })
  }

  return (
    <div>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      <button
        type='button'
        disabled={isLoading}
        onClick={resetData}
        className='btn btn-secondary btn-active-light-primary fw-bold me-2 px-6'
        data-kt-menu-dismiss='true'
        data-kt-user-table-filter='reset'
      >
        Reset
      </button>
      {/* Dropdown Menu */}
      <div
        ref={dropdownRef}
        className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
        data-kt-menu='true'
      >
        {/* Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>

        {/* Separator */}
        <div className='separator border-gray-200'></div>

        {/* Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* Input group */}
          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>Start Date:</label>
            <input
              type='date'
              className='form-control'
              onChange={(e) => setStartDate(e.target.value)}
              value={startDate || ''}
            />
          </div>
          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>End Date:</label>
            <input
              type='date'
              className='form-control'
              onChange={(e) => setEndDate(e.target.value)}
              value={endDate || ''}
            />
          </div>
          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>From Number:</label>
            <input
              type='number'
              className='form-control'
              placeholder='Enter from number...'
              onChange={(e) => setFromNumber(Number(e.target.value))}
              value={fromNumber || ''}
            />
          </div>
          <div className='mb-5'>
            <label className='form-label fs-6 fw-bold'>To Number:</label>
            <input
              type='number'
              className='form-control'
              placeholder='Enter to number...'
              onChange={(e) => setToNumber(Number(e.target.value))}
              value={toNumber || ''}
            />
          </div>
          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-secondary btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
      </div>
    </div>
  )
}

export {UsersListFilter}
