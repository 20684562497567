import {FC} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import MetaDataList from './components/MetaDataList'

const BankingAgentMetaData: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'META.DATA.TITLE'})}</PageTitle>
      <MetaDataList />
    </>
  )
}

export default BankingAgentMetaData
