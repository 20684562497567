import {FC} from 'react'
import {useNavigate} from 'react-router-dom'

type Props = {
  logs?: any
}

const UserLogsCell: FC<Props> = ({logs}) => {
  const navigate = useNavigate()

  const handleViewClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault() // Prevent the anchor's default behavior
    navigate('/session-details', {state: {sessionId: logs?.session_id}})
  }

  return (
    <div className='text-gray-800 text-hover-primary mb-1'>
      <a href='' onClick={handleViewClick}>
        View
      </a>
    </div>
  )
}

export {UserLogsCell}
