export const exampleData = {
  day: {
    labels: ['2024-08-01', '2024-08-02', '2024-08-03', '2024-08-04'],
    datasets: [
      {
        label: 'Bot Handled',
        data: [10, 15, 8, 12],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Human Transferred',
        data: [5, 10, 7, 9],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  },
  week: {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    datasets: [
      {
        label: 'Bot Handled',
        data: [50, 70, 40, 55],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Human Transferred',
        data: [25, 40, 30, 35],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  },
  month: {
    labels: ['August', 'September', 'October', 'November'],
    datasets: [
      {
        label: 'Bot Handled',
        data: [200, 250, 180, 220],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Human Transferred',
        data: [100, 120, 90, 110],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  },
  year: {
    labels: ['2024', '2025', '2026', '2027'],
    datasets: [
      {
        label: 'Bot Handled',
        data: [2400, 2600, 2300, 2700],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Human Transferred',
        data: [1200, 1400, 1100, 1300],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  },
}
