import {FC} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {LoanListWrapper} from './loan-list/LoanList'

const LoanPage: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'LOAN_DETAILS_TITLE'})}</PageTitle>
      <LoanListWrapper />
    </>
  )
}

export default LoanPage
