import moment from 'moment'
import React from 'react'
import {FC} from 'react'

type Props = {
  date: string
}

const CallDate: FC<Props> = ({date}) => (
  <div className='text-gray-600 mb-1'>{moment(date).format('YYYY-MM-DD')}</div>
)

export {CallDate}
