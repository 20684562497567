import {FC} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {OrdersListWrapper} from './orders-list/OrdersList'

const OrderPage: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'ORDERS_DETAILS_TITLE'})}</PageTitle>
      <OrdersListWrapper />
    </>
  )
}

export default OrderPage
