/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {ActiveCall} from '../../core/_models'

type Props = {
  user: ActiveCall
}

const UserInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column'>
      <div className='text-gray-600  mb-1'>{user?.session_id}</div>
    </div>
  </div>
)

export {UserInfoCell}
