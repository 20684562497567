import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {CompletedQueryResponseProvider} from './core/CompletedQueryResponseProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {CompletedSessionsTable} from './table/CompletedSessionsTable'
import {KTCard} from '../../../../_metronic/helpers'

type UsersTableProps = {
  data: any[]
  currentPagination: any // Use appropriate type instead of `any` if you have a model/interface for the data
  setCurrentPagination: any
}

const CompletedSessionsList: React.FC<UsersTableProps> = ({
  data,
  currentPagination,
  setCurrentPagination,
}) => {
  return (
    <>
      <KTCard>
        <UsersListHeader />
        <CompletedSessionsTable
          data={data}
          currentPagination={currentPagination}
          setCurrentPagination={setCurrentPagination}
        />
      </KTCard>
      {/* {itemIdForUpdate !== undefined && <UserEditModal />} */}
    </>
  )
}

const CompletedSessionsListWrapper: React.FC<UsersTableProps> = ({
  data,
  currentPagination,
  setCurrentPagination,
}) => {
  return (
    <QueryRequestProvider>
      <CompletedQueryResponseProvider>
        <ListViewProvider>
          <CompletedSessionsList
            data={data}
            currentPagination={currentPagination}
            setCurrentPagination={setCurrentPagination}
          />
        </ListViewProvider>
      </CompletedQueryResponseProvider>
    </QueryRequestProvider>
  )
}

export {CompletedSessionsListWrapper}
