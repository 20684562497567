// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {Transaction} from '../../core/_models'
import {GuestNameCell} from './GuestNameCell'
import {TransactionTimeCell} from './TransactionTimeCell'

const usersColumns: ReadonlyArray<Column<Transaction>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Transaction Id' className='min-w-125px' />
    ),
    id: 'transaction_id',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Booking Id' className='min-w-125px' />
    ),
    accessor: 'booking',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Guest Name' className='min-w-125px' />
    ),
    id: 'guest_full_name',
    Cell: ({...props}) => <GuestNameCell user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Amount' className='min-w-125px' />
    ),
    id: 'amount',
    Cell: ({...props}) => <UserLastLoginCell amount={props.data[props.row.index].amount} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Payment Date' className='min-w-125px' />
    ),
    id: 'payment_date',
    Cell: ({...props}) => (
      <UserTwoStepsCell payment_done_time={props.data[props.row.index].payment_done_time} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Payment Time' className='min-w-125px' />
    ),
    id: 'payment_time',
    Cell: ({...props}) => (
      <TransactionTimeCell payment_done_time={props.data[props.row.index].payment_done_time} />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Payment Type' className='min-w-125px' />
    ),
    accessor: 'payment_type',
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {usersColumns}
