import {createContext, useState} from 'react'

export const loginContext = createContext<any>(null)

function Context({children}: {children: React.ReactNode}) {
  const [hospitalityPagination, setHospitalityPagination] = useState(null)
  const [doctorAppPagination, setDoctorAppPagination] = useState(null)
  const [logisticsPagination, setLogisticsPagination] = useState(null)
  const [bankPagination, setBankPagination] = useState(null)
  const [filterData, setFilterData] = useState(null)
  const [filterDashboardData, setFilterDashboardData] = useState(null)
  const [agentType, setAgentType] = useState('hospitality_agent')

  return (
    <loginContext.Provider
      value={{
        hospitalityPagination,
        setHospitalityPagination,
        doctorAppPagination,
        setDoctorAppPagination,
        logisticsPagination,
        setLogisticsPagination,
        bankPagination,
        setBankPagination,
        filterData,
        setFilterData,
        agentType,
        setAgentType,
        filterDashboardData,
        setFilterDashboardData,
      }}
    >
      {children}
    </loginContext.Provider>
  )
}

export default Context
