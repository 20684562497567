// @ts-nocheck
import {Column} from 'react-table'
import {UserCustomHeader} from './UserCustomHeader'
import {Account} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<Account>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Account Number' className='min-w-150px' />
    ),
    id: 'account_number',
    accessor: (row) => row?.account_number,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Customer Name' className='min-w-125px' />
    ),
    id: 'name',
    accessor: (row) => row.customer?.name,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Account Balance' className='min-w-150px' />
    ),
    id: 'account_balance',
    accessor: (row) => row?.account_balance,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Account Type' className='min-w-125px' />
    ),
    id: 'account_type',
    accessor: (row) => row?.account_type,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Account Status' className='min-w-125px' />
    ),
    id: 'account_status',
    accessor: (row) => row?.account_status,
  },
]

export {usersColumns}
