import {FC} from 'react'

type Props = {
  call_duration?: boolean
}

const UserTwoStepsCell: FC<Props> = ({call_duration}) => (
  <div className='text-gray-600 mb-1'>{call_duration}</div>
)

export {UserTwoStepsCell}
