import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

const TotalAgentAnalyticsGraph: React.FC<any> = () => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [timeFilter, setTimeFilter] = useState<'day' | 'week' | 'month'>('day')

  const getCSS = (element: HTMLElement, style: string) => {
    return window.getComputedStyle(element).getPropertyValue(style)
  }

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, timeFilter))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, timeFilter])

  return (
    <div className='card mb-5'>
      <div className='card-header border-0 pt-5 px-3'>
        <span className='fs-5 fw-bold'>Top Goals</span>
        <div className='card-toolbar' data-kt-buttons='true'>
          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${
              timeFilter === 'month' ? 'active' : ''
            }`}
            onClick={() => setTimeFilter('month')}
          >
            Month
          </a>
          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${
              timeFilter === 'week' ? 'active' : ''
            }`}
            onClick={() => setTimeFilter('week')}
          >
            Week
          </a>
          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 ${
              timeFilter === 'day' ? 'active' : ''
            }`}
            onClick={() => setTimeFilter('day')}
          >
            Day
          </a>
        </div>
      </div>
      <div className='card-body p-0'>
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '420px'}}></div>
      </div>
    </div>
  )
}

export {TotalAgentAnalyticsGraph}

function getChartOptions(height: number, timeFilter: 'day' | 'week' | 'month'): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-info-light')

  const data = {
    day: [10, 20, 15, 30, 40, 25, 35],
    week: [100, 200, 150, 300, 400, 250, 350],
    month: [1000, 2000, 1500, 3000, 4000, 2500, 3500],
  }

  return {
    series: [
      {
        name: 'Agent Count',
        data: data[timeFilter],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories:
        timeFilter === 'day'
          ? ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          : timeFilter === 'week'
          ? ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7']
          : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      //   labels: {
      //     style: {
      //       colors: labelColor,
      //       fontSize: '12px',
      //     },
      //   },
    },
    // colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
